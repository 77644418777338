import axios from "axios"

const RestApiService = () => {

  class ApiPurchase {

    RESPONSE_MODE_MESSAGE = 1 // Ответ должен выводится в HTML
    RESPONSE_MODE_REDIRECT = 2 // Ответ должен открыть другую страницу

    constructor() { }

    init = (
      spinner = null,
      content = null,
      serial = null,
      validateUrl = null,
      checkoutUrl = null,
      successUrl = null
    ) => {

      this.validateUrl = validateUrl ? validateUrl : null
      this.checkoutUrl = checkoutUrl ? checkoutUrl : null
      this.successUrl = successUrl ? successUrl : null

      this.spinnerElement = spinner ? document.querySelector(spinner) : null
      this.contentElement = content ? document.querySelector(content) : null
      /*
      this.requestFormBodyData = {
        serial: serial,
      }
      */
      this.formData = new FormData();
      if (serial) {
        this.formData.append('serial', serial);
      }
    }

    /**
     * Validate Current License
     */
    validate = () => {
      if (this.spinnerElement && this.contentElement) {
        this.post(this.validateUrl)
      }
    }

    content = function (content) {
      if (this.contentElement) {
        this.contentElement.innerHTML = content
      }
    }

    spinner = function (mode) {
      if (this.spinnerElement) {
        if (mode) {
          $(this.spinnerElement).show();
        } else {
          $(this.spinnerElement).hide();
        }
      }
    }

    ajaxSuccess = (response) => {
      this.spinner(false)
      this.content(response)
    }

    ajaxErrors = (response) => {
      this.spinner(false)
      this.content(response)
    }

    post = async (url) => {
      try {
        const { data } = await axios.post(url, this.formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        const { mode, message } = data
        if (mode && mode == this.RESPONSE_MODE_MESSAGE) {
          this.ajaxSuccess(message)
        } else if (mode === this.RESPONSE_MODE_REDIRECT) {
          window.location.href = message;

        } else {
          this.ajaxSuccess('')
        }

      } catch (error) {
        this.ajaxErrors(`Error: ${error.response.status} ${error.response.data.message}`)
      }
    }
    // Платежный шлюз
    checkout = async (plan_id) => {
      try {
        this.spinner(true)
        this.content('')

        this.formData.append('plan_id', plan_id);

        const { data } = await axios.post(this.checkoutUrl, this.formData, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
        const { mode, message } = data
        if (mode && mode === this.RESPONSE_MODE_REDIRECT) {
          window.location.href = message
        } else {
          this.ajaxSuccess(message)
        }

      } catch (error) {
        console.log(error)
        this.ajaxErrors(`Error: ${error.response.status} ${error.response.data.message}`)
      }
      this.spinner(false)
    }
    // Подписка активна
    checkoutSuccess = (id, hash, url) => {
      if (this.spinnerElement && this.contentElement) {
        this.formData.append('id', id);
        this.formData.append('hash', hash);
        this.post(url)
      }
    }
    // Получаем списки объектов Stripe
    // Например: список всех цен, список, всех подписок и т.д.
    getStripeObject = (url) => {
      this.ajaxSuccess('')
      this.spinner(true)
      this.post(url)
    }
    // Подписка актуальна
    licenseActive = (url) => {
      if (this.spinnerElement && this.contentElement) {
        this.post(url)
      }
    }
    // Подписка отменена
    licenseCancel = (id, hash, url) => {
      if (this.spinnerElement && this.contentElement) {
        this.formData.append('id', id);
        this.formData.append('hash', hash);
        this.post(url)
      }
    }
  }
  window.purchaser = new ApiPurchase()
}



export default RestApiService
