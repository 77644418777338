/**
 * Rest Api Helpers
 */
const RestApiService = () => {

  /**
   *
   * @param {*} postUrl
   * @param {*} postData
   * @param {*} successCallback
   * @param {*} errorCallback
   * @param {*} dataType
   * @param {*} useJson
   */
  window.apiPostRequest = function (postUrl, postData, successCallback, errorCallback, dataType = 'html', useJson = true) {
    $.ajax({
      type: "POST",
      cache: false,
      data: useJson ? JSON.stringify(postData) : postData,
      dataType: dataType ?? 'html',
      url: postUrl,
      success: function (response) {
        successCallback(response)
      },
      error: function (jqXHR, exception) {
        var msg = jqXHR.responseText || 'Error loading'
        errorCallback(msg)
      },
    });
  }

  /**
   *
   * @param {*} postUrl
   * @param {*} postData
   * @param {*} successCallback
   * @param {*} errorCallback
   * @param {*} dataType
   * @param {*} useJson
   */
  window.apiPutRequest = function (postUrl, postData, successCallback, errorCallback, dataType = 'html', useJson = true) {
    $.ajax({
      type: "PUT",
      cache: false,
      data: useJson ? JSON.stringify(postData) : postData,
      dataType: dataType ?? 'html',
      url: postUrl,
      success: function (response) {
        successCallback(response)
      },
      error: function (jqXHR, exception) {
        var msg = jqXHR.responseText || 'Error loading'
        errorCallback(msg)
      },
    });
  }
}

export default RestApiService
