/**
* +--------------------------------------------------------+
* |               Scripts for user front                   |
* +--------------------------------------------------------+
*
* @package codeterium yii2 shop engine
* @author codeterium <codeterium@gmail.com>
*
*/

import RestApiService from "./admin/RestApiService"
import RestApiPurchase from "./purchase/RestApiPurchase"

window.addEventListener('DOMContentLoaded', () => {
  RestApiService()
  RestApiPurchase()
  console.log('Modules loaded')
})
